const menu = {
  supportAndDownload: {
    title: "ข้อมูลและดาวน์โหลด",
    support: "สื่อประชาสัมพันธ์",
    download: "ดาวน์โหลดข้อมูล"
  },
  contactUs: {
    title: "ติดต่อเรา",
    dealer: "ค้นหาตัวแทนจำหน่าย",
    career: "ร่วมงานกับเรา",
    contact: "ติดต่อเรา"
  },
  search: "สิ่งที่ต้องการค้นหา"
}

export default menu
