const career = {
  openPosition: "ตำแหน่งที่เปิดรับสมัคร",
  moreInfo: "สอบถามข้อมูลเพิ่มเติมได้ที่ ฝ่ายทรัพยากรบุคคล บริษัทผลิตภัณฑ์ตราเพชร จำกัด (มหาชน)",
  workWithUs: "ร่วมงานกับเรา",
  detail: "รายละเอียด",
  apply: "สมัครงาน",
  tel: "โทร : ",
  email: "อีเมล : ",
  form: {
    title: "สมัครงานตำแหน่ง",
    name: "ชื่อ-นามสกุล",
    tel: "เบอร์โทรศัพท์",
    email: "อีเมล",
    back: "ย้อนกลับ",
    file: "แนบไฟล์ประวัติ",
    fileBtn: "เลือกไฟล์",
    remarks: "เฉพาะไฟล์ (doc, docx, xls, xlsx, ppt, pptx, jpg, jpeg, png, gif, bmp, pdf) " + "ขนาดไม่เกิน 25 MB",
    submitBtn: "ส่งข้อความถึงเรา"
  },
  accept: "ยินยอม"
}

export default career
