const calculate = {
  calculateBtn: "คำนวณ",
  result: "ผลการคำนวณ",
  fillTheFormRoof: "กรุณากรอกข้อมูล ความกว้าง ความยาว ความลาดชันของหลังคา",
  fillTheForm: "กรุณากรอกข้อมูล ความกว้าง ความยาว",
  remarks1: "*ราคาดังกล่าวเป็นราคาของสินค้าสีมาตรฐานเท่านั้น ราคาดังกล่าวเป็นราคาสินค้าในเขตกรุงเทพ ",
  remarks2: "และปริมณฑลที่ยังไม่รวมภาษีมูลค่าเพิ่มและค่าส่งสินค้า โปรดตรวจสอบจำนวนสินค้าและราคาอีกครั้ง ณ จุดขาย",
  totalPrice: "ราคารวม",
  baht: "บาท",
  hint: "*รุ่นไทยคลาสสิก และเอเลแกนซ์ บวกเพิ่ม 1.5 เท่า",
  note: "หมายเหตุ",
  notice1: "หมายเหตุ: จำนวนวัสดุ และราคา เป็นเพียงประมาณการเบื้องต้นเท่านั้น",
  notice2: "โปรดตรวจสอบจำนวน และราคา กับเจ้าหน้าที่ ณ จุดขายอีกครั้งเพื่อความแม่นยำก่อนสั่งซื้อสินค้า"
}

export default calculate
