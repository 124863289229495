




import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "SectionUnderLine"
})
export default class SectionUnderLine extends Vue {}
