













































import Component, { mixins } from "vue-class-component"
import BreakpointMixin from "../../types/BreakpointMixin"

/** Components */
import ModalSettingPolicy from "@/components/Global/ModalSettingPolicy.vue"

/** Utils */
import { Cookies } from "@/utils/cookies"
import EventBus from "@/plugins/eventBus"

@Component({
  name: "CookiesBar",
  components: { ModalSettingPolicy }
})
export default class CookiesBar extends mixins(BreakpointMixin) {
  /** Variables */
  private isActive: boolean = false

  private close() {
    this.$emit("got-it")
  }

  private settingPolicy() {
    this.isActive = true
  }

  private submitSetting() {
    this.isActive = false
    this.$emit("close", true)
  }

  private get buttonHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 80
    if (bp.lgOnly) return 60
    return 40
  }

  private get buttonWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 130
    if (bp.lgOnly) return 90
    return 91
  }

  private get boxHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 160
    if (bp.lgOnly) return 140
    return 200
  }

  private submitAll() {
    console.log("ALL")

    Cookies.setAnalyticCookies()
    Cookies.setFuctionalCookies()
    Cookies.setAdsCookies()
    const [accKey] = Cookies.acceptCookies
    Cookies.setCookies({ key: accKey, value: "1" })

    this.$emit("close", true)
  }
}
