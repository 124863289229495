const product = {
  filter: {
    close: "Close filter"
  },
  detail: {
    chosenColor: "Chosen color",
    colorRemarks: "Please re-check",
    referenceTitle: "Special Features",
    productColor: "Product Color",
    hint:
      "The color of the product may differ slightly from the color of the actual product, the actual product color in the store should be considered before purchase.",
    compare: "Compare",
    compareProduct: "Compare Products",
    clearCompare: "Clear",
    detail: "Detail",
    delete: "Delete",
    deleteAll: "Delete All",
    messageAlready: "You already have this product on the comparison list.",
    messageAtlease: "Select at least one product to compare.",
    messageNotMatchGroup: "Can compare products only under product groups. the same only.",
    messageMaxLength: "Choose up to 3 products to compare.",
    productName: "Product name",
    usageImage: "Usage Image",
    download: "Download",
    seeVdo: "See Video",
    buttons: {
      manual: "Installation Guide",
      brochure: "Brochure",
      sampleProject: "Site Reference",
      usage: "Usage Image",
      vdo: "Videos",
      docs: "Documents"
    },
    accessories: "Accessories"
  }
}

export default product
