const footer = {
  aboutUs: "เกี่ยวกับเรา",
  ir: "ข้อมูลสำหรับนักลงทุน",
  productWarranty: "เงื่อนไขการรับประกันสินค้า",
  legalAndPolicy: "กฎหมายและนโยบาย",
  cookiesPolicy: "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
  newsAndActivities: "ข่าวสารและกิจกรรม",
  dealers: "ค้นหาตัวแทนจำหน่าย",
  workWithUs: "ร่วมงานกับเรา",
  contactUs: "ติดต่อเรา",
  products: "สินค้าและบริการ",
  download: "ดาวน์โหลดข้อมูล",
  support: "สื่อประชาสัมพันธ์",
  faq: "FAQ",
  sampleProjects: "โครงการตัวอย่าง",
  blog: "บทความ"
}

export default footer
