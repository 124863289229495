









import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  name: "ViewWrapper"
})
export default class ViewWrapper extends Vue {
  @Prop({ default: 8 }) cols!: number

  @Prop({ default: 10 }) lg!: number

  @Prop({ default: "pt-6 pt-md-16" }) layoutStyle!: string

  @Prop({ default: false }) noGutters!: boolean
}
