
































































import { Component, Prop, Vue } from "vue-property-decorator"
import DownloadFooterItem from "@/types/Footer/DownloadFooterItem"
import SupportFooterItem from "@/types/Footer/SupportFooterItem"
import { mapGetters } from "vuex"
import _ from "lodash"

@Component({
  name: "DataAndDownloadMenu",
  components: {},
  computed: { ...mapGetters(["cookiesBar"]) }
})
export default class DataAndDownloadMenu extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: []
  })
  downloads!: DownloadFooterItem[]

  @Prop({
    type: Array,
    required: true,
    default: []
  })
  supports!: SupportFooterItem[]

  private cookiesBar!: boolean

  get mutateDownloads() {
    return this.downloads
  }

  get mutateSupports(): SupportFooterItem[] {
    let head = [
      {
        title: "sampleProjects",
        id: -1,
        static: true
      },
      {
        title: "blog",
        id: 0,
        static: true
      },
      {
        title: "faq",
        id: -2,
        static: true
      }
    ] as SupportFooterItem[]

    if (this.$store.state.menus.blogs !== "ACTIVE") {
      head = head.filter((header) => header.title !== "blog")
    }

    return _.concat(head, this.supports)
  }

  get downloadsTitle() {
    return this.$t("menu.supportAndDownload.download")
  }

  get supportsTitle() {
    return this.$t("menu.supportAndDownload.support")
  }

  // eslint-disable-next-line class-methods-use-this
  private view(model: string, data: SupportFooterItem | DownloadFooterItem) {
    const langQuery = `lang=${localStorage.getItem("lang") || "th"}`
    window.location.href = `/support-and-download?view=${model}&id=${data.id}&lang=${langQuery}`
  }

  private storageLang() {
    return localStorage.getItem("lang") || "th"
  }
}
