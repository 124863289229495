import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import store from "@/store/index"
import AboutUsTitle from "@/components/TitleShape/AboutUsTitle.vue"
import ContactUsTitle from "@/components/TitleShape/ContactUsTitle.vue"
import CookiePolicyTitle from "@/components/TitleShape/CookiePolicyTitle.vue"
import PolicyTitle from "@/components/TitleShape/PolicyTitle.vue"
import NewsTitle from "@/components/TitleShape/NewsTitle.vue"
import CareerTitle from "@/components/TitleShape/CareerTitle.vue"
import DealerTitle from "@/components/TitleShape/DealerTitle.vue"
import SupportAndDownloadTitle from "@/components/TitleShape/SupportAndDownloadTitle.vue"
import ProductTitle from "@/components/TitleShape/ProductTitle.vue"
import CalculateTitle from "@/components/TitleShape/CalculateTitle.vue"
import SearchView from "@/views/Search/SearchView.vue"
import SearchTitle from "@/components/TitleShape/SearchTitle.vue"
import i18n from "@/i18n"

const AboutUs = () => import("@/views/AboutUs/AboutUs.vue")
const CookiesPolicy = () => import("@/views/CookiesPolicy/CookiesPolicy.vue")
const NewsPage = () => import("@/views/News/NewsPage.vue")
const NewsListPage = () => import("@/views/News/NewsListPage.vue")
const NewsDetailPage = () => import("@/views/News/NewsDetailPage.vue")
const CareerPage = () => import("@/views/Career/CareerPage.vue")
const CareerDetailPage = () => import("@/views/Career/CareerDetailPage.vue")
const CareerMainPage = () => import("@/views/Career/CareerMainPage.vue")
const CareerApplyPage = () => import("@/views/Career/CareerApplyPage.vue")
const ContactUsPage = () => import("@/views/ContactUs/ContactUsPage.vue")
const DealerPage = () => import("@/views/Dealers/DealerPage.vue")
const SupportAndDownloadPage = () => import("@/views/SupportAndDownload/SupportAndDownloadPage.vue")
const SupportAndDownloadIndex = () => import("@/components/SupportAndDownload/SupportAndDownloadIndex.vue")
const Policy = () => import("@/views/Policy/Policy.vue")
const ProductMainPage = () => import("@/views/Product/ProductMainPage.vue")
const ProductGroupList = () => import("@/components/Products/ProductGroupList.vue")
const ProductCategoryList = () => import("@/components/Products/ProductCategoryList.vue")
const ProductItemDetail = () => import("@/components/Products/ProductItemDetail.vue")
const Home = () => import("../views/Home/HomePage.vue")
const CalculateMainPage = () => import("@/views/Calculate/CalculateMainPage.vue")
const CalculateList = () => import("@/components/Calculate/CalculateList.vue")
const CalculateCategoryList = () => import("@/components/Calculate/CalculateCategoryList.vue")
const CalculateDetail = () => import("@/components/Calculate/CalculateDetail.vue")

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      titleShape: AboutUsTitle,
      label: "เกี่ยวกับเรา",
      bannerKey: "aboutUs"
    }
  },
  {
    path: "/cookies-policy",
    name: "CookiesPolicy",
    component: CookiesPolicy,
    meta: {
      titleShape: CookiePolicyTitle,
      label: "นโยการใช้คุกกี้",
      bannerKey: "cookiesPolicy"
    }
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
    meta: {
      titleShape: PolicyTitle,
      label: "นโยบายการยอมรับวัตถุประสงค์เพื่อการสมัครงาน",
      bannerKey: "cookiesPolicy"
    }
  },
  {
    path: "/news",
    component: NewsPage,
    meta: {
      label: "ข่าวสารและกิจกรรม",
      bannerKey: "news"
    },
    children: [
      {
        path: "",
        name: "NewsListPage",
        component: NewsListPage,
        meta: {
          titleShape: NewsTitle,
          label: "ข่าวสารและกิจกรรม",
          bannerKey: "news"
        }
      },
      {
        path: "detail/:id?",
        name: "NewsDetailPage",
        component: NewsDetailPage,
        meta: {
          titleShape: NewsTitle,
          label: "ข่าวสารและกิจกรรม",
          bannerKey: "news"
        }
      }
    ]
  },
  {
    path: "/career",
    meta: {
      titleShape: CareerTitle,
      label: "ร่วมงานกับเรา",
      bannerKey: "career"
    },
    component: CareerPage,
    children: [
      {
        path: "",
        component: CareerMainPage,
        name: "CareerMainPage",
        meta: {
          titleShape: CareerTitle,
          label: "ร่วมงานกับเรา",
          bannerKey: "career"
        }
      },
      {
        path: "detail",
        component: CareerDetailPage,
        name: "CareerDetailPage",
        meta: {
          titleShape: CareerTitle,
          label: "ร่วมงานกับเรา",
          bannerKey: "career"
        }
      },
      {
        path: "apply",
        component: CareerApplyPage,
        name: "CareerApplyPage",
        meta: {
          titleShape: CareerTitle,
          label: "ร่วมงานกับเรา",
          bannerKey: "career"
        }
      }
    ]
  },
  {
    path: "/contact-us",
    component: ContactUsPage,
    name: "ContactUs",
    meta: {
      titleShape: ContactUsTitle,
      label: "ติดต่อเรา",
      bannerKey: "contactUs"
    }
  },
  {
    name: "Dealers",
    path: "/dealers",
    component: DealerPage,
    meta: {
      titleShape: DealerTitle,
      label: "ค้นหาตัวแทนจำหน่าย",
      bannerKey: "dealers"
    }
  },
  {
    name: "SupportAndDownload",
    path: "/support-and-download",
    component: SupportAndDownloadPage,
    meta: {
      titleShape: SupportAndDownloadTitle,
      label: "ข้อมูลและดาวน์โหลด",
      bannerKey: "supportAndDownload"
    }
  },
  {
    name: "SupportAndDownloadIndex",
    path: "/support-and-download/index",
    component: SupportAndDownloadIndex,
    meta: {
      titleShape: SupportAndDownloadTitle,
      label: "ข้อมูลและดาวน์โหลด",
      bannerKey: "supportAndDownload"
    }
  },
  {
    name: "Products",
    path: "/products",
    component: ProductMainPage,
    meta: {
      label: "สินค้าและบริการ",
      bannerKey: "products"
    },
    children: [
      {
        name: "ProductGroupList",
        path: "",
        component: ProductGroupList,
        meta: {
          titleShape: ProductTitle,
          label: "สินค้าและบริการ",
          bannerKey: "products"
        }
      },
      {
        name: "ProductCategoryList",
        path: "group/:id",
        component: ProductCategoryList,
        meta: {
          titleShape: ProductTitle,
          label: "สินค้าและบริการ",
          bannerKey: "products",
          extraClasses: "fill-height"
        }
      },
      {
        name: "ProductItemDetail",
        path: "group/:id/detail/:detailId",
        component: ProductItemDetail,
        meta: {
          titleShape: ProductTitle,
          label: "สินค้าและบริการ",
          bannerKey: "products"
        }
      }
    ]
  },
  {
    name: "Calculate",
    component: CalculateMainPage,
    path: "/calculate",
    meta: {
      titleShape: CalculateTitle,
      bannerKey: "calculate"
    },
    children: [
      {
        name: "CalculateList",
        component: CalculateList,
        path: "",
        meta: {
          titleShape: CalculateTitle,
          bannerKey: "calculate"
        }
      },
      {
        name: "CalculateCategoryList",
        component: CalculateCategoryList,
        path: "category",
        meta: {
          titleShape: CalculateTitle,
          bannerKey: "calculate"
        }
      },
      {
        name: "CalculateDetail",
        component: CalculateDetail,
        path: "detail",
        meta: {
          titleShape: CalculateTitle,
          bannerKey: "calculate"
        }
      }
    ]
  },
  {
    path: "/search",
    name: "Search",
    component: SearchView,
    meta: {
      titleShape: SearchTitle,
      bannerKey: "search"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.query.top === "true" || to.query.top) {
      return {
        y: 0,
        x: 0
      }
    }
    return savedPosition
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const lang = to.query.lang as string
  switch (lang) {
    case "th":
      i18n.locale = "th"
      await store.dispatch("setThaiLanguage")
      break
    case "en":
      i18n.locale = "en"
      await store.dispatch("setEnglishLanguage")
      break
    default:
      break
  }
  if (router.resolve(to.fullPath).resolved.name === null) {
    next("/")
  } else {
    next()
  }
})
export default router
