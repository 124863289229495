






















import { Component, Vue } from "vue-property-decorator"
import { mapGetters } from "vuex"

/** GET Image */
import ContactUsApi from "@/api/ContactUs/ContactUsApi"

// import ContactUsTitle from "@/components/TitleShape/ContactUsTitle.vue"
// import DealerTitle from "@/components/TitleShape/DealerTitle.vue"
// import SupportAndDownloadTitle from "@/components/TitleShape/SupportAndDownloadTitle.vue"

@Component({
  name: "ContactUsMenu",
  components: {},
  computed: { ...mapGetters(["cookiesBar"]) }
})
export default class ContactUsMenu extends Vue {
  selected = null
  baseURL = process.env.VUE_APP_FILE_PATH
  private imageUrl = ""

  // eslint-disable-next-line class-methods-use-this
  private viewPage(link: string) {
    const lookup: { [Key: string]: string } = {
      ContactUs: "/contact-us",
      Dealers: "/dealers",
      CareerMainPage: "/career"
    }
    const lang = this.$i18n.locale
    window.location.href = `${lookup[link]}?lang=${lang}`
    return `${lookup[link]}?lang=${lang}`
    // this.$router.push({ name: link, query: { lang } });
  }

  private generateRoute(link: string) {
    const lookup: { [Key: string]: string } = {
      ContactUs: "/contact-us",
      Dealers: "/dealers",
      CareerMainPage: "/career"
    }
    const lang = this.$i18n.locale
    return `${lookup[link]}?lang=${lang}`
  }

  /** Mounted */
  private mounted() {
    ContactUsApi.getSubMenuBanner().then((data) => {
      this.imageUrl = this.baseURL + String(data.file.file_path)
    })
  }

  private template = [
    {
      id: 0,
      label: "contact",
      link: "ContactUs"
    },
    {
      id: 1,
      label: "dealer",
      link: "Dealers"
    },
    {
      id: 2,
      label: "career",
      link: "CareerMainPage"
    }
  ]
}
