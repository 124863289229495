
























































import { Component, PropSync, Prop, Watch } from "vue-property-decorator"
import _ from "lodash"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "ModalBase"
})
export default class ModalBase extends mixins(BreakpointMixin) {
  @PropSync("value") isActive!: boolean;

  @Prop({ default: "Title" }) readonly ["title"]!: number;

  @Prop({ default: "center" }) readonly ["title-align"]!: number;

  @Prop({ default: 1000 }) readonly ["max-width"]!: number;

  @Prop({ default: false }) readonly ["hide-close"]!: boolean;

  @Prop({ default: false }) readonly ["hide-title"]!: boolean;

  @Prop({ default: false }) readonly ["hide-footer"]!: boolean;

  @Prop({ default: false }) readonly ["visible-inside"]!: boolean;

  @Prop({ default: false }) readonly ["show-delete"]!: boolean;

  @Prop({ default: false }) readonly ["padding-less"]!: boolean;

  @Prop({ default: false }) readonly ["height-auto"]!: boolean

  get buttonSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndUp) return "60px"
    return "23px"
  }

  get iconSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndUp) return "40px"
    return "23px"
  }
}
