const home = {
  others: "เพิ่มเติม",
  activity: "ข่าวสารและกิจกรรม",
  activities: "ดูข่าวสารและกิจกรรมทั้งหมด",
  info: "อัพเดทข้อมูลข่าวสารและกิจกรรมต่าง ๆ ที่น่าสนใจของ ‘ตราเพชร’",
  announcement: "ดูประกาศ",
  video: "วิดีโอโฆษณา",
  products: "ดูสินค้าและบริการทั้งหมด",
  product: "สินค้าและบริการ",
  articles: "ดูบทความทั้งหมด",
  article: "บทความ",
  article_description: "บทความที่ให้ข้อมูลการติดตั้ง, การดูแลผลิตภัณฑ์ และเทคนิคต่างๆ ที่น่าสนใจ"
}

export default home
