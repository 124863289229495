






































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator"
import ContactUsMenu from "@/components/SubMenus/ContactUsMenu.vue"
import DataAndDownloadMenu from "@/components/SubMenus/DataAndDownloadMenu.vue"
import SearchMenu from "@/components/SubMenus/SearchMenu.vue"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import MenuApi from "@/api/Menu/MenuApi"
import MainMenu from "@/types/Menu/MainMenu"
import SubMenu from "@/types/Menu/SubMenu"
import DiamondFile from "@/types/DiamondFile"
import AppApi from "@/api/app/AppApi"
import SubMenuBody from "@/components/SubMenus/SubMenuBody.vue"
import Footer from "@/types/Footer/Footer"
import Logo from "@/types/App/Logo"
import DownloadFooterItem from "@/types/Footer/DownloadFooterItem"
import SupportFooterItem from "@/types/Footer/SupportFooterItem"
import { mapGetters } from "vuex"
// @ts-ignore
import THIcon from "../assets/languages/th.png"
// @ts-ignore
import ENIcon from "../assets/languages/en.png"

@Component({
  name: "AppBar",
  components: {
    SubMenuBody,
    ContactUsMenu,
    DataAndDownloadMenu,
    SearchMenu
  },
  computed: { ...mapGetters(["language"]) }
})
export default class AppBar extends mixins(BreakpointMixin) {
  @Prop() height!: string

  @Prop() toggleMobileMenu!: Function

  /** Variables */
  private search = ""

  private sortBy = _.sortBy

  private language!: string

  private leftMenus: MainMenu[] = []

  private logoUrl = ""

  private rightMenus: MainMenu[] | { id: number; label: string }[] = []

  private subMenus: { [key: number]: { data: SubMenu[]; image: DiamondFile } } = {}

  private subMenuEnter = false

  private shrink = false

  private isSearchMenu = false

  private hoverId = -1

  private languages = {
    th: {
      label: "ไทย",
      icon: THIcon,
      value: "th"
    },
    en: {
      label: "Eng",
      icon: ENIcon,
      value: "en"
    }
  }

  private hoverSide = "left"

  private selectMenu = false

  private downloads: DownloadFooterItem[] = []

  private supports: SupportFooterItem[] = []

  private timeInterval = 0

  private seconds = 0

  /** Cycles */
  created() {
    window.addEventListener("scroll", this.handleScroll)
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    this.fetchData()
  }

  /** Getters */
  private get isDownloadMenu() {
    // @ts-ignore
    return this.rightMenus[this.hoverId - this.leftMenus.length]?.id === 100 && !this.isSearchMenu
  }

  private get isContactUsMenu() {
    // @ts-ignore
    return this.rightMenus[this.hoverId - this.leftMenus.length]?.id === 101 && !this.isSearchMenu
  }

  get mouseOverAnyMenu(): boolean {
    return this.hoverId !== -1
  }

  get extensionHeight() {
    if (this.$vuetify.breakpoint.xlOnly) {
      return this.isSearchMenu ? "307px" : "672px"
    }
    return this.isSearchMenu ? "240px" : "449px"
  }

  /** Methods */
  private fetchData() {
    AppApi.getLogo()
      .then(({ data }) => {
        const logoObj = deserialize<Logo>(data, Logo)
        // eslint-disable-next-line global-require
        this.logoUrl = logoObj.file?.fileUrl || require("@/assets/logo.jpg").default()
      })
      .catch(() => {
        // eslint-disable-next-line global-require
        this.logoUrl = require("@/assets/logo.jpg")
      })
    MenuApi.getMainMenu().then(({ data }) => {
      const ret: MainMenu[] = _.map(data, (d) => deserialize<MainMenu>(d, MainMenu))
      const left: MainMenu[] = []
      const right: MainMenu[] = []
      // console.log(ret);
      ret.forEach((d, idx) => {
        MenuApi.getSubMenu(d.id)
          // eslint-disable-next-line no-shadow
          .then(({ data }) => {
            const subRet: SubMenu[] = _.map(data, (subD) => deserialize<SubMenu>(subD, SubMenu))
            this.subMenus[idx] = {
              data: subRet,
              image: d.file
            }
          })
        if (idx <= 3) {
          left.push(d)
        } else {
          right.push(d)
        }
      })
      this.leftMenus = left

      this.rightMenus = [
        ...right,
        {
          id: 100,
          label: this.$t("menu.supportAndDownload.title") as string,
          static: false,
          link: `/support-and-download/index${this.generateRoute()}`,
          file: new DiamondFile()
        },
        {
          id: 101,
          label: "contactUs.title",
          static: true,
          link: "",
          file: new DiamondFile()
        }
      ]
    })
    AppApi.getFooter().then(({ data }) => {
      const footer = deserialize<Footer>(data, Footer)
      this.downloads = footer.download
      this.supports = footer.support
    })
  }

  private stopTimer() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval)
    }
  }

  private closeSearch() {
    this.isSearchMenu = false
    this.search = ""
  }

  handleClickOutSide(event: Event) {
    if (event.target) {
      if (
        this.isSearchMenu &&
        // @ts-ignore
        event.target.id !== "search-btn" &&
        // @ts-ignore
        event.target.id !== "search-icon" &&
        // @ts-ignore
        event.target.id !== "search-mobile-icon"
      ) {
        this.closeSearch()
      }
    }
  }

  delayMouseLeave() {
    const currHoverId = this.hoverId
    this.stopTimer()
    setTimeout(() => {
      if (!this.subMenuEnter && this.hoverId === currHoverId) {
        this.seconds = 0
        this.hoverId = -1
        this.shrink = window.scrollY > 260
      }
    }, 500)
  }

  handleShrink() {
    if (window.scrollY > 260) {
      this.shrink = true
    }
    if (window.scrollY < 260) {
      this.shrink = false
    }
  }

  toggleSearchMenu() {
    // console.log(this.isSearchMenu)
    this.isSearchMenu = !this.isSearchMenu
    this.hoverId = -1
  }

  toggleSubMenu(id: number, side: string) {
    this.hoverSide = side
    if (this.hoverId === -1) {
      this.timeInterval = setInterval(() => {
        this.seconds += 1
        if (this.seconds >= 2) {
          this.openSubMenu()
          if (this.isSearchMenu || id === this.hoverId) {
            return
          }
          this.hoverId = id
          // this.shrink = false
          this.stopTimer()
        }
      }, 300)
    } else {
      this.hoverId = id
      // this.shrink = false
      this.stopTimer()
    }
  }

  closeSubMenu() {
    if (this.isSearchMenu) {
      return
    }
    setTimeout(() => {
      this.subMenuEnter = false
      this.shrink = window.scrollY > 260
      this.hoverId = -1
    }, 200)
  }

  openSubMenu() {
    this.subMenuEnter = true
  }

  // eslint-disable-next-line class-methods-use-this
  onClickLogo() {
    if (this.$route.name === "Home") return
    const lang = this.$i18n.locale
    this.$router.push({
      name: "Home",
      query: { lang }
    })
  }

  previousScroll = 0

  handleScroll() {
    // Any code to be executed when the window is scrolled
    if (this.previousScroll < window.scrollY) {
      this.previousScroll = window.scrollY
      this.subMenuEnter = false
      this.shrink = window.scrollY > 260
      this.hoverId = -1
      return
    }
    this.previousScroll = window.scrollY
    if (this.isSearchMenu || this.mouseOverAnyMenu) return
    this.handleShrink()
  }

  // eslint-disable-next-line class-methods-use-this
  selectLanguage(lang: { icon: string; label: string; value: string }) {
    if (this.$i18n.locale === lang.value) return
    this.$i18n.locale = lang.value
    const query = _.cloneDeep(this.$route.query)
    query.lang = lang.value
    // @ts-ignore
    if (this.$route.name === "ProductCategoryList" ){
      const paramsId = this.$route.params.id
      const path = paramsId === "21" ? "25" : paramsId === "25" ? "21" : (parseInt(paramsId) > 10 ? parseInt(paramsId.charAt(1)).toString() : "1" + paramsId)
      window.location.href = `/products/group/${path}?lang=${lang.value}&top=true`
    //   this.$router.push({
    //   path: paramsId === "21" ? "25" : paramsId === "25" ? "21" : (parseInt(paramsId) > 10 ? parseInt(paramsId.charAt(1)).toString() : "1" + paramsId),
    //   query,
    // })
    } else {
      this.$router.push({
      name: `${this.$route.name}`,
      query,
      params: this.$route.params,
    })
    }

    // this.$router.push({
    //   path: `/products/group/${query.lang === 'th'? '2':'12'}`,
    //   query
    // })
    this.$store.commit("clearCompare")
  }

  // eslint-disable-next-line class-methods-use-this
  private gotoUrl(url: string) {
    if (url !== "undefined") window.open(url, "_self")
  }

  private generateRoute() {
    const locale = this.$i18n.locale || "TH"
    return `/?lang=${locale}`
  }
}
