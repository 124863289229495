




import { Component, Vue } from "vue-property-decorator"
import TitleShape from "@/components/TitleShape.vue"

@Component({
  name: "AboutUsTitleShape",
  components: { TitleShape }
})
export default class AboutUsTitle extends Vue {}
