




import { Component, Vue } from "vue-property-decorator"
import TitleShape from "@/components/TitleShape.vue"

@Component({
  name: "DealerTitle",
  components: { TitleShape }
})
export default class DealerTitle extends Vue {}
