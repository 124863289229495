const support = {
  title: "Support information",
  tabs: {
    faq: "FAQ",
    siteRef: "Diamond Site Ref.",
    blog: "Blog"
  },
  blog: {
    yearPrefix: "Blog "
  }
}

export default support
