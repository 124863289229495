







































































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import SearchApi from "@/api/Search/SearchApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import SearchResult from "@/types/Search/SearchResult"
import ViewWrapper from "@/views/ViewWrapper.vue"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import MapDialog from "@/components/Global/MapDialog.vue"
import BreakpointMixin from "../../types/BreakpointMixin"

@Component({
  name: "SearchView",
  components: { MapDialog, SectionUnderLine, ViewWrapper }
})
export default class SearchView extends mixins(BreakpointMixin) {
  private mapHeaders = ["dealers", "branch"]

  private careerHeaders = ["career_description", "career_banner", "career_executive", "career_footer"]

  private staticHeaders: { [key: string]: unknown } = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    // career_page: {
    //   title: 'title.career',
    //   route: 'CareerMainPage',
    // },
    company: {
      title: "aboutUs.tabName.companyProfile",
      route: "AboutUs",
      query: { id: 0 }
    },
    location: {
      title: "aboutUs.companyProfile.locationTitle",
      route: "AboutUs",
      query: { id: 0 }
    },
    vision: {
      title: "aboutUs.companyVision.vision",
      route: "AboutUs",
      query: { id: 1 }
    },
    mission: {
      title: "aboutUs.companyVision.mission",
      route: "AboutUs",
      query: { id: 1 }
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    core_value: {
      title: "aboutUs.companyVision.values",
      route: "AboutUs",
      query: { id: 1 }
    },
    strategy: {
      title: "aboutUs.tabName.strategy",
      route: "AboutUs",
      query: { id: 2 }
    },
    policy: {
      title: "aboutUs.standard.policy",
      route: "AboutUs",
      query: { id: 3 }
    },
    activity: {
      title: "aboutUs.standard.activity",
      route: "AboutUs",
      query: { id: 3 }
    },
    csr: {
      title: "aboutUs.tabName.csr",
      route: "AboutUs",
      query: { id: 4 }
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    cookie_policy: {
      title: "cookiesPolicy.title",
      route: "CookiesPolicy"
    }
  }

  private result: SearchResult[] = []

  private mapDialog = false

  private searching = false

  private mapContent = ""

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private viewMap(content?: string) {
    if (!content) return
    this.mapContent = content
    this.mapDialog = true
  }

  private fetchData() {
    const q = this.$route.query.q || ""
    this.searching = true
    SearchApi.serch(`${q}`)
      .then(({ data }) => {
        this.result = _.map(data, (d) => deserialize<SearchResult>(d, SearchResult))
      })
      .finally(() => {
        this.searching = false
      })
  }

  mounted() {
    this.fetchData()
  }

  // private viewDetail(item: SearchResult) {
  //   const { type } = item
  //   const query = {
  //     lang: this.$i18n.locale,
  //     top: "true"
  //   }
  //   // console.log(type)
  //   if (_.keys(this.staticHeaders).includes(type)) {
  //     // @ts-ignore
  //     const { route, query: staticQuery } = this.staticHeaders[type]
  //     this.$router.push({
  //       name: route,
  //       query: { ...staticQuery, ...query }
  //     })
  //   } else if (type === "product") {
  //     this.$router.push({
  //       name: "ProductItemDetail",
  //       params: { id: `${item.productGroupId}`, detailId: `${item.productCategoryId}` },
  //       query
  //     })
  //   } else if (type === "news") {
  //     this.$router.push({
  //       name: "NewsDetailPage",
  //       params: { id: `${item.newsId}` },
  //       query
  //     })
  //   } else if (type === "blogs") {
  //     this.$router.push({
  //       name: "SupportAndDownload",
  //       query: {
  //         view: "support",
  //         id: "0",
  //         state: "1",
  //         blog: `${item.blogId}`
  //       }
  //     })
  //   } else if (type === "career") {
  //     this.$router.push({
  //       name: "CareerDetailPage",
  //       query: {
  //         id: `${item.careerId}`
  //       }
  //     })
  //   } else if (type === "faq") {
  //     this.$router.push({
  //       name: "SupportAndDownload",
  //       query: {
  //         ...this.$route.query,
  //         id: "-2",
  //         view: "support"
  //       }
  //     })
  //   } else if (type === "site_ref") {
  //     this.$router.push({
  //       name: "SupportAndDownload",
  //       query: {
  //         ...this.$route.query,
  //         id: "-1",
  //         view: "support",
  //         group: `${item.productGroupId}`,
  //         category: `${item.productCategoryId}`,
  //         name: item.productCategory,
  //         state: "2"
  //       }
  //     })
  //   } else if (type === "career_page") {
  //     this.$router.push({
  //       name: "CareerMainPage",
  //       query: this.$route.query
  //     })
  //   } else if (type === "download") {
  //     this.$router.push({
  //       name: "SupportAndDownload",
  //       query: {
  //         ...this.$route.query,
  //         id: `${item.downloadHeaderId}`,
  //         view: "download"
  //       }
  //     })
  //   } else if (type === "support") {
  //     this.$router.push({
  //       name: "SupportAndDownload",
  //       query: {
  //         ...this.$route.query,
  //         id: `${item.supportHeaderId}`,
  //         view: "support"
  //       }
  //     })
  //   }
  // }

  private generateRoute(item: SearchResult) {
    const { type } = item
    const query = {
      lang: this.$i18n.locale,
      top: "true"
    }
    // console.log(type)
    if (_.keys(this.staticHeaders).includes(type)) {
      // @ts-ignore
      const { route, query: staticQuery } = this.staticHeaders[type]
      return {
        name: route,
        query: { ...staticQuery, ...query }
      }
    } else if (type === "product") {
      return {
        name: "ProductItemDetail",
        params: { id: `${item.productGroupId}`, detailId: `${item.productCategoryId}` },
        query
      }
    } else if (type === "news") {
      return {
        name: "NewsDetailPage",
        params: { id: `${item.newsId}` },
        query
      }
    } else if (type === "blogs") {
      return {
        name: "SupportAndDownload",
        query: {
          view: "support",
          id: "0",
          state: "1",
          blog: `${item.blogId}`
        }
      }
    } else if (type === "career") {
      return {
        name: "CareerDetailPage",
        query: {
          id: `${item.careerId}`
        }
      }
    } else if (type === "faq") {
      return {
        name: "SupportAndDownload",
        query: {
          ...this.$route.query,
          id: "-2",
          view: "support"
        }
      }
    } else if (type === "site_ref") {
      return {
        name: "SupportAndDownload",
        query: {
          ...this.$route.query,
          id: "-1",
          view: "support",
          group: `${item.productGroupId}`,
          category: `${item.productCategoryId}`,
          name: item.productCategory,
          state: "2"
        }
      }
    } else if (type === "career_page") {
      return {
        name: "CareerMainPage",
        query: this.$route.query
      }
    } else if (type === "download") {
      return {
        name: "SupportAndDownload",
        query: {
          ...this.$route.query,
          id: `${item.downloadHeaderId}`,
          view: "download"
        }
      }
    } else if (type === "support") {
      return {
        name: "SupportAndDownload",
        query: {
          ...this.$route.query,
          id: `${item.supportHeaderId}`,
          view: "support"
        }
      }
    }
  }
}
