const global = {
  app: "ตราเพชร",
  back: "ย้อนกลับ",
  menu: "เมนู",
  seeMore: "ดูเพิ่มเติม",
  noSearchResult: "ไม่พบผลการค้นหา",
  close: "ปิด",
  all: "ทั้งหมด"
}
export default global
