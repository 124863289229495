








import { Component, Vue } from "vue-property-decorator"
import TitleShape from "@/components/TitleShape.vue"

@Component({
  name: "CareerTitle",
  components: { TitleShape }
})
export default class CareerTitle extends Vue {
  get textSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) return "20px"
    return "36px"
  }

  get padddingRight() {
    const bp = this.$vuetify.breakpoint
    if (bp.smAndDown) return "5"
    return "0"
  }

  get textTitle() {
    return this.$t("title.policy")
  }
}
