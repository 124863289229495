const global = {
  app: "DIAMOND",
  back: "Back",
  menu: "Menu",
  seeMore: "See more",
  noSearchResult: "No search result",
  close: "Close",
  all: "All"
}
export default global
