const dealer = {
  prefix: "Dealers",
  modernTrade: "Modern Trade",
  leadTrade: "Local dealers",
  nextBtn: "Next",
  prevBtn: "Previous",
  map: "Map",
  sortBy: "Sort by",
  alphabet: "A-Z",
  table: {
    shopName: "Shop name",
    tel: "Tel.",
    address: "Address"
  }
}

export default dealer
