import { JsonProperty, Serializable } from "typescript-json-serializer"
import ProductFooterItem from "@/types/Footer/ProductFooterItem"
import SupportFooterItem from "@/types/Footer/SupportFooterItem"
import DownloadFooterItem from "@/types/Footer/DownloadFooterItem"

@Serializable()
export default class Footer {
  @JsonProperty({ type: ProductFooterItem }) product!: Array<ProductFooterItem>

  @JsonProperty({ type: SupportFooterItem }) support!: Array<SupportFooterItem>

  @JsonProperty({ type: DownloadFooterItem }) download!: Array<DownloadFooterItem>
}
