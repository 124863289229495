





































import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  name: "TitleShape"
})
export default class TitleShape extends Vue {
  @Prop({ default: "" }) text!: string

  @Prop({ default: 100 }) width!: number

  @Prop({ default: 32 }) textXOffset!: number

  @Prop({ default: 88 }) textXOffsetLg!: number

  @Prop({ default: 44 }) textYOffsetLg!: number

  @Prop({ default: 162 }) textXOffsetXl!: number

  @Prop({ default: 70 }) textYOffsetXl!: number

  @Prop({ default: 327 }) boxWidthLg!: number

  @Prop({ default: 491 }) boxWidthXl!: number

  @Prop({ default: 0 }) boxHeightXl!: number

  @Prop({ default: 0 }) boxHeightLg!: number

  // eslint-disable-next-line class-methods-use-this
  get textOffset(): number {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return this.textXOffsetXl
    if (bp.mdAndUp) return this.textXOffsetLg
    return this.textXOffset
  }

  get textYOffset() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) return this.textYOffsetLg
    return this.textYOffsetXl
  }

  get fontSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) return 24
    return 40
  }

  get boxHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) return this.boxHeightLg || 72
    return this.boxHeightXl || 108
  }

  // eslint-disable-next-line class-methods-use-this
  get boxWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgOnly) {
      return this.boxWidthLg
    } else if (bp.xsOnly) {
      return bp.width + 38
    } else {
      return this.boxWidthXl
    }
    // const textElement = document.getElementById('titleShapeTextElement');
    // // @ts-ignore
    // return (textElement?.getComputedTextLength() || this.width) + 280;
  }

  get boundingBox() {
    const topRightY = this.boxWidth - 40.0
    return `0,0 0,${this.boxHeight} ${this.boxWidth},${this.boxHeight} ${topRightY},0`
  }

  get textSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) return "24px"
    return "40px"
  }
}
