import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class VisibleMenu {
  @JsonProperty("all_menu_id") id!: number

  @JsonProperty("know_as") knowAs!: string

  @JsonProperty("title") title!: string

  @JsonProperty("status") status!: string
}