const cookiesPolicy = {
  title: "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
  bar: {
    text1: "เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพในการใช้เว็บไซต์ ท่านสามารถศึกษารายละเอียดการใช้คุกกี้ได้จาก",
    textLink: "“นโยบายคุ้มครองข้อมูลส่วนบุคคล”",
    text2: "และสามารถเลือกตั้งค่ายินยอมการใช้คุกกี้ได้โดยคลิก “การตั้งค่าคุกกี้”",
    setting: "การตั้งค่าคุกกี้",
    acceptAll: "ยอมรับทั้งหมด"
  },
  popup: {
    title: "ศูนย์การตั้งค่าความเป็นส่วนตัว",
    description:
      "เมื่อคุณเข้าชมเว็บไซต์อาจมีการจัดเก็บหรือดึงข้อมูลจากเบราว์เซอร์ของคุณ ส่วนใหญ่อยู่ในรูปแบบของคุกกี้ ซึ่งมักเป็นข้อมูลที่ไม่สามารถระบุตัวตน ของคุณได้โดยตรง แต่ช่วยให้คุณใช้งานเว็บตามความต้องการส่วนบุคคลได้ดียิ่งขึ้น คุณสามารถปฏิเสธไม่ให้คุกกี้บางประเภททำงานได้ โดยการตั้งค่าคุกกี้",
    confirm: "ยืนยันตัวเลือก",
    confirmAll: "ยืนยันทั้งหมด",
    requiredCookies: {
      title: "คุกกี้ที่จำเป็น (Required Cookies)",
      description:
        "ใช้เพื่อเปิดใช้งานการทำงานหลักของเว็บไซต์ คุกกี้ประเภทนี้ช่วยให้ประสบการณ์การใช้เว็บไซต์ของผู้เข้าชมเว็บไซต์เป็นไปได้อย่างต่อเนื่อง เช่น การจดจำการเข้าสู่ระบบ การจดจำข้อมูลที่ผู้เข้าชมเว็บไซต์ให้ไว้บนเว็บไซต์",
      alwaysOn: "เปิดใช้งานตลอดเวลา"
    },
    analyticalCookies: {
      title: "คุกกี้เพื่อการวิเคราะห์ (Analytical/Performance Cookies)",
      description:
        "คุกกี้ประเภทนี้ช่วยให้บริษัทสามารถวัดผลการทำงานต่าง ๆ เช่น การประมวลจำนวนหน้าที่ผู้เข้าชมเว็บไซต์เข้าใช้งาน นับจำนวนผู้เข้าเยี่ยมชมเว็บไซต์ ทราบถึงพฤติกรรมในการเยี่ยมชมเว็บไซต์ เพื่อปรับปรุงการทำงานของเว็บไซต์ให้มีคุณภาพดีขึ้นและมีความเหมาะสมมากขึ้น"
    },
    fuctionalityCookies: {
      title: "คุกกี้เพื่อช่วยในการใช้งาน (Functionality Cookies)",
      description:
        "คุกกี้ประเภทนี้ใช้ในการจดจำตัวผู้เข้าชมเว็บไซต์เมื่อผู้เข้าชมเว็บไซต์กลับมาใช้งานเว็บไซต์อีกครั้ง ซึ่งจะช่วยให้บริษัทสามารถปรับแต่งเนื้อหาสำหรับผู้เข้าชมเว็บไซต์ ปรับให้เว็บไซต์ของบริษัทตอบสนองความต้องการใช้งานของผู้เข้าชมเว็บไซต์ รวมถึงจดจำการตั้งค่าของผู้เข้าชมเว็บไซต์ เช่น ภาษา หรือขนาดของตัวอักษรที่ผู้เข้าชมเว็บไซต์เลือกใช้ในการใช้งานในเว็บไซต์"
    },
    advertisingCookies: {
      title: "คุกกี้เพื่อปรับเนื้อหาให้เข้ากับกลุ่มเป้าหมาย (Advertising Cookies)",
      description:
        "คุกกี้ประเภทนี้ใช้ในการจดจำสิ่งที่ผู้เข้าชมเว็บไซต์เคยเยี่ยมชม เพื่อนำเสนอสินค้า บริการ หรือสื่อโฆษณาที่เกี่ยวข้องเพื่อให้ตรงกับความสนใจของผู้เข้าชมเว็บไซต์ คุกกี้ประเภทนี้จะถูกบันทึกบนอุปกรณ์ของผู้เข้าชมเว็บไซต์เพื่อเก็บข้อมูลการเข้าใช้งานและลิงก์ที่ผู้เข้าชมเว็บไซต์ได้เยี่ยมชมและติดตาม นอกจากนี้ คุกกี้จากบุคคลที่สามอาจใช้ข้อมูลที่มีการส่งต่อข่าวสารในสื่อออนไลน์และเนื้อหาที่จัดเก็บจากการให้บริการ เพื่อเข้าใจความหมายของผู้ใช้งานโดยมีวัตถุประสงค์ในการปรับแต่งเว็บไซต์ โฆษณาให้เหมาะสมกับความสนใจของผู้เข้าชมเว็บไซต์"
    }
  },
  msgRequired: "โปรดตรวจสอบการอนุมัติการใช้งานคุกกี้เพื่อใช้งานต่อไป"
}

export default cookiesPolicy
