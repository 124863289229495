import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import MainMenu from "@/types/Menu/MainMenu"
import VisibleMenu from "@/types/Menu/VisibleMenu"

export default class MenuApi extends DiamondBaseAPI {
  static getMainMenu(): Promise<ResponseBase<MainMenu>> {
    return this.api.get("menu").then(({ data }) => data)
  }

  static getSubMenu(subMenuId: string | number): Promise<ResponseBase<MainMenu>> {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const params = { menu_id: subMenuId }
    return this.api.get("menu-sub", { params }).then(({ data }) => data)
  }

  static getVisibleMenu(knowAs: any): Promise<ResponseBase<VisibleMenu>> {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const params = { ...knowAs }
    return this.api.get("all-menus", { params }).then(({ data }) => data)

    // FINDALL: `/all-menus/admin`,
    // FIND_BY_ID: `/all-menus/admin/:id`,
  }
}
