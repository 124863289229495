import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "BreakpointMixin"
})
export default class BreakpointMixin extends Vue {
  get mobileScreen(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get xl(): boolean {
    return this.$vuetify.breakpoint.xlOnly
  }

  get lg(): boolean {
    return this.$vuetify.breakpoint.mdAndUp && !this.$vuetify.breakpoint.xlOnly
  }
}
