







































































































































































































































import { mapGetters, mapMutations } from "vuex"
import { MetaInfo } from "vue-meta"
import { mixins } from "vue-class-component"
import MainMenu from "@/types/Menu/MainMenu"
import SubMenu from "@/types/Menu/SubMenu"
import { Component, Watch } from "vue-property-decorator"
import { deserialize } from "typescript-json-serializer"
import EventBus from "@/plugins/eventBus"
import _ from "lodash"

import AppBar from "@/components/AppBar.vue"
import TitleShape from "@/components/TitleShape.vue"

import AppApi from "@/api/app/AppApi"
import MenuApi from "@/api/Menu/MenuApi"
import BreakpointMixin from "@/types/BreakpointMixin"
import Banner from "@/types/App/Banner"
import SEO from "@/types/App/SEO"
import Footer from "@/types/Footer/Footer"
import VisibleMenu from "@/types/Menu/VisibleMenu"
import SupportFooterItem from "@/types/Footer/SupportFooterItem"
import DownloadFooterItem from "@/types/Footer/DownloadFooterItem"
import ProductFooterItem from "@/types/Footer/ProductFooterItem"
import DiamondFile from "@/types/DiamondFile"

import FooterPage from "@/components/Footer.vue"
import CookiesBar from "@/components/Global/CookiesBar.vue"

import { Cookies } from "@/utils/cookies"

@Component({
  name: "App",
  components: {
    CookiesBar,
    Footer: FooterPage,
    AppBar,
    TitleShape
  },
  computed: {
    ...mapGetters(["cookiesBar"]),
    queryLang() {
      return this.$route.query.lang
    }
  },
  metaInfo(this: App) {
    return this.metaInfo
  }
})
export default class App extends mixins(BreakpointMixin) {
  private cookiesBar!: boolean
  private showCookiesBar: boolean = false
  public mobileData: any
  private toggleSection = 1
  private subMenus: { [key: number]: { data: any } } = {}
  private toggleIndex = -1
  public metaInfo: MetaInfo = {}
  private bannerUrl = ""

  private model = {
    IR: ""
  }

  private ContractUstemplate = [
    {
      id: 0,
      label: "contact",
      link: "ContactUs"
    },
    {
      id: 1,
      label: "dealer",
      link: "Dealers"
    },
    {
      id: 2,
      label: "career",
      link: "CareerMainPage"
    }
  ]

  private closeCookiesBar() {
    localStorage.setItem("cookiesBar", "false")
    this.$store.dispatch("closeCookiesBar")
  }

  private footer = new Footer()

  private productDetailBanner = new DiamondFile()

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange(newVal: unknown) {
    // Router from vue-router
    const {
      route: { name }
    } = this.$router.resolve({ path: window.location.pathname })
    if (name !== "ProductItemDetail") {
      // @ts-ignore
      AppApi.getBanner(this.banner[newVal.meta.bannerKey]).then(({ data }) => {
        const banner = deserialize<Banner>(data, Banner)
        this.bannerUrl = banner.file?.fileUrl || ""
      })
    } else {
      this.bannerUrl = this.productDetailBanner.fileUrl || ""
    }
    AppApi.getFooter().then(({ data }) => {
      this.footer = deserialize<Footer>(data, Footer)
      const tailSupport = this.footer.support
      let headSupport = [
        {
          title: "faq",
          id: -2,
          static: true
        },
        {
          title: "sampleProjects",
          id: -1,
          static: true
        },
        {
          title: "blog",
          id: 0,
          static: true
        }
      ] as SupportFooterItem[]

      if (this.$store.state.menus.blogs !== "ACTIVE") {
        headSupport = headSupport.filter((header) => header.title !== "blog")
      }

      this.footer.support = _.concat(headSupport, tailSupport)
    })
    this.fetchData()
    if(this.mobileScreen)
    this.fetchMenuData()

    const lang = localStorage.getItem("lang") ? `${localStorage.getItem("lang")}`.toUpperCase() : "TH"

    const menus = { blogs: `BLOG-${lang}`, activities: `INFO-ACTIVITY-${lang}` }

    MenuApi.getVisibleMenu({}).then(({ data }) => {
      const allMenus = _.map(data, (d) => deserialize<VisibleMenu>(d, VisibleMenu))
      const blogStatus = allMenus.find((item) => item.knowAs === menus.blogs)?.status || "ACTIVE"
      const activityStatus = allMenus.find((item) => item.knowAs === menus.activities)?.status || "ACTIVE"

      const payload = { blogs: blogStatus, activities: activityStatus }
      this.$store.commit("setMenus", payload)
    })

    document.title = `${this.$t("global.app")}`
  }

  @Watch("queryLang")
  onParamsChange() {
    this.fetchData()
  }

  private gotoUrl(url: string) {
    if (url !== "undefined") window.open(url, "_self")
  }

  private gotoSubUrl(menu_id: string, menu_sub_id: string) {
    const langQuery = `?lang=${localStorage.getItem("lang") || "th"}`
    window.location.href = `/products/group/${menu_id}/detail/${menu_sub_id}${langQuery}`
  }

  private toggleMenu(section: number,index: number) {
    if (section !== this.toggleSection) {
      this.toggleIndex = -1
    }
    this.toggleSection = section
    if (index === this.toggleIndex) {
    this.toggleIndex = -1
    return
    }
    this.toggleIndex = index
  }

  private fetchBanner() {
    const {
      route: { name }
    } = this.$router.resolve({ path: window.location.pathname })
    if (name === "ProductItemDetail") {
      this.bannerUrl = this.productDetailBanner.fileUrl || ""
    } else {
      let { routeKey } = this
      if (!this.routeKey) {
        const resolved = this.$router.resolve({
          path: window.location.pathname
        })
        // console.log('route', this.$route, resolved);
        routeKey = resolved.route.meta.bannerKey
      }
      // @ts-ignore
      AppApi.getBanner(this.banner[routeKey]).then(({ data }) => {
        const banner = deserialize<Banner>(data, Banner)
        this.bannerUrl = banner.file?.fileUrl!
      })
    }
  }

  private fetchData() {
    this.fetchBanner()
    AppApi.getSEO().then(({ data }) => {
      const seo = deserialize<SEO>(data, SEO)
      this.metaInfo.meta = [
        {
          name: "keywords",
          content: seo.keywords
        },
        {
          name: "DC_title",
          content: seo.dcTitle
        },
        {
          name: "description",
          content: seo.description
        }
      ]
    })
    AppApi.getFooter().then(({ data }) => {
      this.footer = deserialize<Footer>(data, Footer)
      const tailSupport = this.footer.support
      let headSupport = [
        {
          title: "sampleProjects",
          id: -1,
          static: true
        },
        {
          title: "blog",
          id: 0,
          static: true
        },
        {
          title: "faq",
          id: -2,
          static: true
        }
      ] as SupportFooterItem[]
      if (this.$store.state.menus.blogs !== "ACTIVE") {
        headSupport = headSupport.filter((header) => header.title !== "blog")
      }
      this.footer.support = _.concat(headSupport, tailSupport)
    })
    AppApi.getIRLink().then(({ data: { link } }) => {
      this.model.IR = link
    })
  }

  async mounted() {
    this.fetchData()
    EventBus.$on("change-banner", (banner?: DiamondFile) => {
      if (banner) {
        this.productDetailBanner = banner
        this.bannerUrl = this.productDetailBanner.fileUrl || ""
      }
    })

    await new Promise((r) => setTimeout(r, 1000))

    const lang = this.$route.query.lang || localStorage.getItem("lang")

    if (this.$route.name === "Home" && !this.$route.query.lang) {
      localStorage.removeItem("lang")
      this.$store.commit("setLanguage", "th")
      localStorage.setItem("lang", "th")
      this.$router.push({ query: { lang: "th" } })
    } else if (!lang) {
      localStorage.removeItem("lang")
      this.$store.commit("setLanguage", "th")
      localStorage.setItem("lang", "th")
      this.$router.push({ query: { lang: "th" } })
    }

    if (!Cookies.getCookieByKey("_dbp_acc")) {
      this.showCookiesBar = true
    }

    if (Cookies.getCookieByKey("_dbp_acc_ana")) {
      Cookies.setAnalyticCookies()
    }
  }
    private fetchMenuData() {
    MenuApi.getMainMenu().then(({ data }) => {
      this.mobileData = data
      this.mobileData.forEach((d: any, index: number) => {
        MenuApi.getSubMenu(d.menu_id)
          .then(({ data }) => {
            this.subMenus[index] = {
              data: data,
            }
          })
      })
    })
  }

  private closeCookieBar() {
    this.showCookiesBar = false
  }


  private generateContractUsRoute(link: string) {
    const lookup: { [Key: string]: string } = {
      ContactUs: "/contact-us",
      Dealers: "/dealers",
      CareerMainPage: "/career"
    }
    const lang = this.$i18n.locale
    return `${lookup[link]}?lang=${lang}`
  }


  private get appBarHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return "176px"
    }
    if (bp.mdAndUp) {
      return "123px"
    }
    return "56px"
  }

  private get heroRatio() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndUp) {
      return 5.4
    }
    return 3.5
  }

  private get isHome() {
    return this.$route.name === "Home"
  }

  private generateRoute(name: string) {
    if (this.$route.name !== name) {
      return {
        name,
        query: {
          top: "true",
          lang: this.$i18n.locale
        }
      }
    } else {
      return { href: "#" }
    }
  }

  private get routeKey(): string {
    const resolved = this.$router.resolve({
      path: window.location.pathname
    })
    if (!this.$route) {
      return resolved.route.meta.bannerKey
    }
    return this.$route.meta.bannerKey
  }

  private get routeTitle() {
    return this.$route.meta.titleShape
  }

  private get extraClasses() {
    return this.$route.meta.extraClasses || ""
  }

  private get fabSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 80
    }
    return 55
  }

  private get fabIconSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 40
    }
    return 30
  }

  private get heroHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndUp) {
      return 350
    }
    return 160
  }

  private get msgSnack() {
    return this.$store.state.msgSnack
  }

  private toggleSnack(isActive: boolean) {
    this.$store.commit("toggleSnack", isActive)
  }

  private isMobileMenuOpen = false

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen
  }

  private viewRoute(item: { link: string; type: string; query: object }) {
    const query = item.query || {}
    if (item.type === "ROUTER" && this.$route.name !== item.link) {
      this.$router.push({
        name: item.link,
        query: { top: "true", ...query }
      })
      // @ts-ignore
    } else if (item.type === "MODEL") window.open(this.model[item.link], "_blank")
  }

  // eslint-disable-next-line class-methods-use-this
  private viewFooter(model: string, data: ProductFooterItem | SupportFooterItem | DownloadFooterItem) {
    const langQuery = `lang=${localStorage.getItem("lang") || "th"}`
    if (model === "product") {
      window.location.href = `/products/group/${data.id}?${langQuery}`
    } else {
      window.location.href = `/support-and-download?view=${model}&id=${data.id}&${langQuery}`
    }
  }

  private templates = [
    {
      title: "aboutUs",
      items: [
        {
          label: "aboutUs",
          bold: true,
          hover: false,
          link: "AboutUs",
          type: "ROUTER"
        },
        {
          label: "ir",
          bold: true,
          hover: false,
          link: "IR",
          type: "MODEL"
        },
        // {
        //   label: 'productWarranty',
        //   bold: true,
        //   hover: false,
        //   type: 'ROUTER',
        //   link: 'SupportAndDownload',
        //   query: {
        //     view: 'support',
        //     id: 2,
        //   },
        // },
        // {
        //   label: 'cookiesPolicy',
        //   bold: true,
        //   hover: false,
        //   link: 'CookiesPolicy',
        //   type: 'ROUTER',
        // },
        {
          label: "newsAndActivities",
          bold: true,
          hover: false,
          link: "NewsListPage",
          type: "ROUTER"
        },
        {
          label: 'dealers',
          bold: true,
          hover: false,
          link: 'Dealers',
          type: 'ROUTER',
        },
        {
          label: "workWithUs",
          bold: true,
          hover: false,
          link: "CareerMainPage",
          type: "ROUTER"
        },
        {
          label: "contactUs",
          bold: true,
          hover: false,
          link: "ContactUs",
          type: "ROUTER"
        },
        {
          label: "cookiesPolicy",
          bold: true,
          hover: false,
          link: "CookiesPolicy",
          type: "ROUTER"
        }
      ]
    },
    {
      title: "products",
      link: "ProductGroupList",
      model: "product"
    },
    {
      title: "download",
      link: "SupportAndDownloadIndex",
      model: "download"
    },
    {
      title: "support",
      model: "support"
    }
  ]

  private get template() {
    return this.templates.map((template) => {
      if (template.items) {
        template.items = template.items.filter((item) => {
          if (this.$store.state.menus.activities === "ACTIVE") {
            return item
          } else {
            return item.label !== "newsAndActivities"
          }
        })
        return template
      } else {
        return template
      }
    })
  }

  banner = {
    aboutUs: "about_us",
    cookiesPolicy: "cookie",
    news: "news",
    career: "career",
    contactUs: "contact",
    dealers: "dealer",
    supportAndDownload: "support_download",
    products: "product",
    calculate: "calculate",
    search: "search"
  }
}
