




import { Component, Vue } from "vue-property-decorator"
import TitleShape from "@/components/TitleShape.vue"

@Component({
  name: "SupportAndDownloadTitle",
  components: { TitleShape }
})
export default class SupportAndDownloadTitle extends Vue {}
