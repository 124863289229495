import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"
import SubMenu from "@/types/Menu/SubMenu"
import DownloadFooterItem from "@/types/Footer/DownloadFooterItem"
import SupportFooterItem from "@/types/Footer/SupportFooterItem"
import ContractUsMenu from "@/types/Menu/ContracUsMenu"

@Serializable()
export default class MainMenu {
  @JsonProperty("menu_id") id!: number

  @JsonProperty("link") link!: string

  @JsonProperty("title") label!: string

  @JsonProperty("file") file: DiamondFile = new DiamondFile()

  static = false
}

export interface MobileMenu {
  id: number
  link: string
  label: string
  submenu: SubMenu[] | (DownloadFooterItem | SupportFooterItem)[] | ContractUsMenu[]
}
