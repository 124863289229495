import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#e95d0f",
        secondary: "#fabb00"
      }
    },
    options: { customProperties: true }
  }
  // breakpoint: {
  //   thresholds: {
  //     md: 960,
  //     lg: 1280,
  //   },
  // },
})
